import React from 'react';
import AuthUser from "./AuthUser";

const Dashboard = () => {
    const {user} = AuthUser();
    return (
        <div>
            HELLO <small><b>{user.firstname}</b> <b>{user.lastname}</b> your email is <b>{user.email}</b></small>
        </div>
    );
};

export default Dashboard;