export const cleanService = () => {
  sessionStorage.removeItem("officeData");
  sessionStorage.removeItem("selectedService");
  sessionStorage.removeItem("selectedOffice");
  sessionStorage.removeItem("selectedSlots");
  sessionStorage.removeItem("officeServiceId");
  sessionStorage.removeItem("nameOffice");
  sessionStorage.removeItem("selectedDate");
  sessionStorage.removeItem("details");
  sessionStorage.removeItem("selectedStartTime");
  sessionStorage.removeItem("selectedEndTime");
  sessionStorage.removeItem("selectedBlockTime");
};
