import React, { useState } from "react";
import Conferma from "./components/Conferma";
import Riepilogo from "./components/Riepilogo";

export const Step5 = ({
  appointmentsConfirmed,
  setAppointmentsConfirmed,
  stepSet,
}) => {
  if (!appointmentsConfirmed) {
    return (
      <Riepilogo
        setAppointmentsConfirmed={setAppointmentsConfirmed}
        stepSet={stepSet}
      />
    );
  } else {
    return <Conferma />;
  }
};
