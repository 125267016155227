import React from "react";
import Calendar from "../calendar/Calendar";

export const Step2 = ({
  savedBlockId,
  setSavedBlockId,
  selectedSlots,
  setSelectedSlots,
}) => {
  const officeData = JSON.parse(sessionStorage.getItem("officeData"));

  return (
    <div className={"row mt-5"}>
      <Calendar
        savedBlockId={savedBlockId}
        setSavedBlockId={setSavedBlockId}
        selectedSlots={selectedSlots}
        setSelectedSlots={setSelectedSlots}
      />
      <section className="it-page-section mt-5" id="office">
        <div className="cmp-card">
          <div className="bg-gray-100 shadow-sm p-10">
            <div className="card-header border-0 p-0 mb-lg-30">
              <div className="d-flex">
                <h2 className="title-xxlarge mb-0">Ufficio</h2>
              </div>
            </div>
            <div className="card-body p-0 mt-4">
              <div className="cmp-info-summary bg-white mb-4 mb-lg-30 p-4">
                <div className="card">
                  <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                    <h4 className="font-semibold mb-3">{`
                    ${officeData.name} - ${officeData.city}
                    `}</h4>
                  </div>

                  <div className="card-body p-0">
                    <div className="single-line-info border-light mt-3">
                      <div className="text-paragraph-small">Indirizzo</div>
                      <div className="border-light">
                        <p className="data-text font-semibold">
                          {`${officeData.address} ${officeData.civico} - ${officeData.city}, ${officeData.cap}`}
                        </p>
                      </div>
                    </div>
                    {!!officeData?.recapiti && (
                      <>
                        <hr />
                        <div className="single-line-info border-light">
                          <div className="text-paragraph-small">Recapiti</div>
                          <div className="border-light">
                            <p
                              className="data-text font-semibold"
                              dangerouslySetInnerHTML={{
                                __html: officeData.recapiti,
                              }}
                            ></p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="card-footer p-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
