import { useEffect, useState } from "react";
import { BASE_URL } from "../../server";
import getHeaders from "../../utils/handleHeaderFunc";

const ServiceDetails = ({ serviceId }) => {
  const [servicesData, setServicesData] = useState({});
  const savedService = sessionStorage.getItem("selectedService");

  useEffect(() => {
    const headers = getHeaders();
    fetch(`${BASE_URL}/service/${serviceId}`, {
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setServicesData(data.service);
      })
      .catch((error) => console.error(error));
  }, [serviceId]);

  return (
    <div>
      {servicesData?.what_is && (
        <>
          <h2 className={"mb-3"}>Descrizione del servizio</h2>
          <h4>Cos'é</h4>
          <p dangerouslySetInnerHTML={{ __html: servicesData.what_is }}></p>
          {!!servicesData.what_can_do && (
            <>
              <h4>Cosa puoi fare</h4>
              <p
                dangerouslySetInnerHTML={{ __html: servicesData.what_can_do }}
              ></p>
            </>
          )}
          {!!servicesData.nofication_before && (
            <>
              <h4>Note servizio</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: servicesData.nofication_before,
                }}
              ></p>
            </>
          )}

          {!!servicesData.recapiti && (
            <>
              {" "}
              <h4>Recapiti</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: servicesData.recapiti,
                }}
              ></p>
            </>
          )}

          <p dangerouslySetInnerHTML={{ __html: servicesData.address }}></p>
          <p dangerouslySetInnerHTML={{ __html: servicesData.mail }}></p>
          <p dangerouslySetInnerHTML={{ __html: servicesData.city }}></p>
          <p dangerouslySetInnerHTML={{ __html: servicesData.civico }}></p>
          <p dangerouslySetInnerHTML={{ __html: servicesData.province }}></p>
        </>
      )}
    </div>
  );
};

export default ServiceDetails;
