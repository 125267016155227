import React, { useEffect, useState } from "react";
import ServiceDetails from "../../pages/ServiceDetails";
import { BASE_URL } from "../../../server";
import getHeaders from "../../../utils/handleHeaderFunc";

export const Step1 = ({
  savedOffice,
  savedService,
  setSavedOffice,
  setSavedService,
}) => {
  const [selectedOffice, setSelectedOffice] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [availableServices, setAvailableServices] = useState([]);
  const [offices, setOffices] = useState([]);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // API call to fetch offices

    // aggiungere header per autenticazione

    const headers = getHeaders();

    fetch(`${BASE_URL}/offices`, {
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setOffices(data.offices);
        // Restore previous selections from localStorage if present
        /*       const savedOffice = sessionStorage.getItem("selectedOffice");
        const savedService = sessionStorage.getItem("selectedService"); */
        if (savedOffice) {
          setSelectedOffice(savedOffice);
          setSavedOffice(savedOffice);
          const office = data.offices.find((item) => item.id === savedOffice);
          if (office) {
            const services = office.services || [];
            setAvailableServices(services);
          }
        }
        if (savedService) {
          setSelectedService(savedService);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const handleOfficeChange = (event) => {
    const selectedOfficeId = event.target.value;
    setSelectedOffice(selectedOfficeId);
    setSavedOffice(selectedOfficeId);
    const office = offices.find((item) => item.id === selectedOfficeId);
    const officeSelectedInformation = office ? office.name : "";
    const services = office ? office.services : [];
    setSelectedService("");
    setSavedService("");

    setAvailableServices(services);

    setIsValid(false);

    // get selected office data

    const officeData = offices.find((item) => item.id === selectedOfficeId);
    const stringedOfficeData = JSON.stringify(officeData);
    sessionStorage.setItem("selectedOffice", selectedOfficeId);
    sessionStorage.setItem("nameOffice", officeSelectedInformation);
    sessionStorage.setItem("officeData", stringedOfficeData);

    // clean up from previous selections
    sessionStorage.removeItem("selectedService");
    sessionStorage.removeItem("officeServiceId");
    sessionStorage.removeItem("selectedStartTime");
    sessionStorage.removeItem("selectedEndTime");
    sessionStorage.removeItem("selectedDate");
    sessionStorage.removeItem("selectedBlockId");
    sessionStorage.removeItem("selectedBlock");
    sessionStorage.removeItem("serviceData");
    sessionStorage.removeItem("selectedSlots");
  };

  const handleServiceChange = (event) => {
    const selectedServiceId = event.target.value;
    setSelectedService(selectedServiceId);
    setSavedService(selectedServiceId);
    setIsValid(!!selectedServiceId);

    // filtro i servizi per id
    const service = availableServices.find(
      (item) => item.id === selectedServiceId
    );
    // prendo l'office service id
    const officeServiceId = service ? service.office_service_id : "";

    /*     onValid(isValid); */

    sessionStorage.setItem("selectedService", selectedServiceId);
    sessionStorage.setItem("officeServiceId", officeServiceId);
    sessionStorage.setItem("serviceData", JSON.stringify(service));
    // clean up from previous selections
    sessionStorage.removeItem("selectedStartTime");
    sessionStorage.removeItem("selectedEndTime");
    sessionStorage.removeItem("selectedDate");
    sessionStorage.removeItem("selectedBlockId");
    sessionStorage.removeItem("selectedBlock");
    sessionStorage.removeItem("selectedSlots");
  };

  const isOfficeWithServices = (officeId) => {
    const office = offices.find((item) => item.id === officeId);
    return office && office.services.length > 0;
  };

  return (
    <div className="row mt-5">
      <div className="it-page-sections-container mb-5">
        <section className="it-page-section" id="office">
          <div className="cmp-card">
            <div className="bg-gray-100 shadow-sm p-10">
              <div className="card-header border-0 p-0 mb-lg-30">
                <div className="d-flex">
                  <h2 className="title-xxlarge mb-2">Ufficio</h2>
                </div>
                <p className="subtitle-small mb-3">
                  Scegli l’ufficio a cui vuoi richiedere l’appuntamento
                </p>
              </div>
              <div className="card-body p-0">
                <div className="select-wrapper p-0 select-partials">
                  <label htmlFor="office-option" className="visually-hidden">
                    Tipo di ufficio
                  </label>
                  <select
                    id="office-option"
                    data-focus-mouse="false"
                    data-enpassusermodified="yes"
                    value={selectedOffice}
                    onChange={handleOfficeChange}
                  >
                    <option value="">Seleziona un ufficio</option>
                    {offices?.map((office) => (
                      <option key={office.id} value={office.id}>
                        {office.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {selectedOffice && isOfficeWithServices(selectedOffice) ? (
        <div className="it-page-sections-container mb-5">
          <section className="it-page-section" id="office">
            <div className="cmp-card">
              <div className="bg-gray-100 shadow-sm p-10">
                <div className="card-header border-0 p-0 mb-lg-30">
                  <div className="d-flex">
                    <h2 className="title-xxlarge mb-2">Servizi</h2>
                  </div>
                  <p className="subtitle-small mb-3">
                    Scegli il servizio a cui vuoi richiedere l’appuntamento
                  </p>
                </div>
                <div className="card-body p-0">
                  <div className="select-wrapper p-0 select-partials">
                    <label htmlFor="service-option" className="visually-hidden">
                      Tipo di servizio
                    </label>
                    <select
                      id="service-option"
                      data-focus-mouse="false"
                      data-enpassusermodified="yes"
                      value={selectedService}
                      onChange={handleServiceChange}
                    >
                      <option value="">Seleziona un servizio</option>
                      {availableServices?.map((service) => (
                        <option key={service.id} value={service.id}>
                          {service.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : selectedOffice ? (
        <div className="form-group col-md-6">
          <div className="select-wrapper">
            <strong>
              {selectedService
                ? true
                : "Non ci sono servizi disponibili per l'ufficio selezionato."}
            </strong>
          </div>
        </div>
      ) : null}

      {selectedService && <ServiceDetails serviceId={selectedService} />}
    </div>
  );
};

export default Step1;
