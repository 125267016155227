// component with iframe to logout from the server

import React, { useEffect } from "react";

const LogoutPage = () => {
  return (
    <div>
      Logout in corso...
      <iframe
        style={{
          opacity: 1,
        }}
        src="https://trevisoapp.comune.treviso.it/user/logout"
        title="logout"
        width="100%"
        height="600px"
      />
    </div>
  );
};
export default LogoutPage;

// Compare this snippet from client/src/backend/pages/LogoutPage.js:
