import React from "react";

export const Step3 = ({ details, setDetails }) => {
  const handleDetailsChange = (event) => {
    const details = event.target.value;
    setDetails(details);
    sessionStorage.setItem("details", details);
  };

  return (
    <div className={"row mt-5"}>
      <section className="form-group" id="details">
        <div className="cmp-card">
          <div className="bg-gray-100 shadow-sm p-10">
            <div className="card-header border-0 p-0 mb-lg-30 m-0">
              <div className="d-flex">
                <h2 className="title-xxlarge mb-0">Dettagli</h2>
              </div>
              <p className="subtitle-small mb-0 mb-3">
                Aggiungi ulteriori dettagli
              </p>
            </div>
            <div className="card-body p-0">
              <div className="cmp-text-area p-0">
                <div className="form-group">
                  <label htmlFor="form-details" className="visually-hidden">
                    Aggiungi ulteriori dettagli
                  </label>
                  <textarea
                    value={details}
                    onChange={handleDetailsChange}
                    className="text-area"
                    id="form-details"
                    rows="4"
                    data-focus-mouse="false"
                  ></textarea>
                  <span className="label">Inserire massimo 200 caratteri</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
