import React from "react";
import { format } from "date-fns";
import { it } from "date-fns/locale";

const Riepilogo = ({ stepSet }) => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const selectedStartTime = sessionStorage.getItem("selectedStartTime");
  const selectedEndTime = sessionStorage.getItem("selectedEndTime");
  const selectedDate = sessionStorage.getItem("selectedDate");
  const details = sessionStorage.getItem("details");

  const officeData = JSON.parse(sessionStorage.getItem("officeData"));
  const serviceData = JSON.parse(sessionStorage.getItem("serviceData"));

  // format date with date-fns leike 'Giovedì 11 marzo 2022'
  const formattedDate = format(new Date(selectedDate), "EEEE d MMMM yyyy", {
    locale: it,
  });

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <div className="mt-2">
            <h2 className="visually-hidden">Dettagli dell'appuntamento</h2>
            <div className="cmp-card mb-4">
              <div className="bg-gray-100 shadow-sm p-10 mb-0">
                <div className="card-header border-0 p-0 mb-lg-30">
                  <div className="d-flex">
                    <h3 className="subtitle-large mb-4">Riepilogo</h3>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="cmp-info-summary bg-white mb-3 mb-lg-4 p-3 p-lg-4">
                    <div className="card">
                      <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                        <h4 className="title-large-semi-bold mb-3">Ufficio</h4>
                        <div onClick={() => stepSet(1)}>
                          <span
                            className="text-button-sm-semi text-primary cursor-pointer  hover:underline font-semibold
                          "
                          >
                            Modifica
                          </span>
                        </div>
                      </div>

                      <div className="card-body p-0">
                        <div className="single-line-info border-light">
                          <div className="text-paragraph-small mt-3">
                            Tipologia ufficio
                          </div>
                          <div className="border-light">
                            <p className="data-text font-semibold">
                              {`${officeData.name} - ${officeData.city}`}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="single-line-info border-light">
                          <div className="text-paragraph-small">
                            Municipalità
                          </div>
                          <div className="border-light">
                            <p className="data-text font-semibold">
                              {`${officeData.address} ${officeData.civico} - ${officeData.city}, ${officeData.cap}`}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                  <div className="cmp-info-summary bg-white mb-3 mb-lg-4 p-3 p-lg-4">
                    <div className="card">
                      <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                        <h4 className="title-large-semi-bold mb-3">
                          Data e orario
                        </h4>
                        <div
                          onClick={() => stepSet(2)}
                          className="text-decoration-none"
                        >
                          <span
                            className="text-button-sm-semi text-primary cursor-pointer hover:underline font-semibold
                          "
                          >
                            Modifica
                          </span>
                        </div>
                      </div>

                      <div className="card-body p-0">
                        <div className="single-line-info border-light">
                          <div className="text-paragraph-small mt-3">Data</div>
                          <div className="border-light">
                            <p className="data-text font-semibold capitalize">
                              {formattedDate}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="single-line-info border-light">
                          <div className="text-paragraph-small">Ora</div>
                          <div className="border-light">
                            <p className="data-text font-semibold">{` ${selectedStartTime} - ${selectedEndTime}`}</p>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                  <div className="cmp-info-summary bg-white mb-3 mb-lg-4 p-3 p-lg-4">
                    <div className="card">
                      <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                        <h4 className="title-large-semi-bold mb-3">
                          Dettagli appuntamento
                        </h4>
                        <div
                          onClick={() => stepSet(1)}
                          className="text-decoration-none"
                        >
                          <span
                            className="text-button-sm-semi text-primary cursor-pointer  hover:underline font-semibold
                          "
                          >
                            Modifica
                          </span>
                        </div>
                      </div>

                      <div className="card-body p-0">
                        <div className="single-line-info border-light">
                          <div className="text-paragraph-small mt-3">
                            Servizio
                          </div>
                          <div className="border-light">
                            <p className="data-text font-semibold">
                              {serviceData.name}
                            </p>
                          </div>
                        </div>
                        {!!details && (
                          <>
                            <hr />
                            <div className="single-line-info border-light">
                              <div className="text-paragraph-small">
                                Dettagli
                              </div>
                              <div className="border-light">
                                <p className="data-text font-semibold">
                                  {details}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                  <div className="cmp-info-summary bg-white p-3 p-lg-4 mb-0">
                    <div className="card">
                      <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                        <h4 className="title-large-semi-bold mb-3">
                          Richiedente
                        </h4>
                      </div>

                      <div className="card-body p-0">
                        <div className="single-line-info border-light">
                          <div className="text-paragraph-small mt-3">Nome</div>
                          <div className="border-light">
                            <p className="data-text font-semibold">
                              {userData.firstname}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="single-line-info border-light">
                          <div className="text-paragraph-small">Cognome</div>
                          <div className="border-light">
                            <p className="data-text font-semibold">
                              {userData.lastname}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div className="single-line-info border-light">
                          <div className="text-paragraph-small">Email</div>
                          <div className="border-light">
                            <p className="data-text font-semibold">
                              {userData.email}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer p-0"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Riepilogo;
