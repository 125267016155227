import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AuthUser() {
  const navigate = useNavigate();

  const getToken = () => {
    const userToken = sessionStorage.getItem("user_jwt");
    const basicToken = sessionStorage.getItem("basic_token");
    if (!userToken && !basicToken) return null;
    else if (userToken) return userToken;
    else
      return basicToken
        .replace("Basic ", "")
        .replace("basic ", "")
        .replace("BASIC ", "");
  };

  const getUser = () => {
    const userString = sessionStorage.getItem("user");
    const userDetail = JSON.parse(userString);
    return userDetail;
  };

  const saveToken = (user, token) => {
    sessionStorage.setItem("token", JSON.stringify(token));
    sessionStorage.setItem("user", JSON.stringify(user));
    setToken(token);
    setUser(user);
    navigate("/");
  };
  const logout = () => {
    const bearerTokenPresent = sessionStorage.getItem("user_jwt");
    console.log("logout", bearerTokenPresent);
    sessionStorage.clear();
    if (!!bearerTokenPresent) {
      window.location.href =
        "https://trevisoapp.comune.treviso.it/user/logout?bbs_custom_redirect=https://frontend.agendatreviso.bbsitalia.com";
    } else {
      console.log("logout senza bearer token");
      window.location.href = "/";
    }
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const http = async (url, options) => {
    try {
      const response = await fetch(url, { ...options });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    setToken: saveToken,
    token,
    user,
    getToken,
    logout,
    http,
  };
}
