import React, { useEffect, useMemo, useState } from "react";
import { Step1 } from "../step1/Step1";
import icons from "bootstrap-italia/dist/svg/sprites.svg";
import { Step3 } from "../step3/Step3";
import { Step2 } from "../step2/Step2";
import { Step4 } from "../step4/Step4";
import axios from "axios";
import AuthUser from "../../../backend/pages/AuthUser";
import { Step5 } from "../step5/Step5";
import { Link } from "react-router-dom";
import { cleanService } from "../../../utils/storageFunc";
import { BASE_URL } from "../../../server";
import getHeaders from "../../../utils/handleHeaderFunc";

const Wizard = () => {
  const [appointmentsConfirmed, setAppointmentsConfirmed] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [savedOffice, setSavedOffice] = useState(
    sessionStorage.getItem("selectedOffice") || ""
  );
  const [savedService, setSavedService] = useState(
    sessionStorage.getItem("selectedService") || ""
  );

  const [savedBlockId, setSavedBlockId] = useState(
    sessionStorage.getItem("selectedBlockId") || ""
  );

  const [selectedSlots, setSelectedSlots] = useState(
    JSON.parse(sessionStorage.getItem("selectedSlots")) || []
  );

  const [details, setDetails] = useState("");

  const { http, setToken } = AuthUser();

  const isValidStep1 = useMemo(() => {
    return !!savedOffice && !!savedService;
  }, [savedOffice, savedService]);

  const isValidStep2 = useMemo(() => {
    return !!savedBlockId;
  }, [savedBlockId]);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
    window.scrollTo(0, 0);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const stepSet = (step) => {
    setCurrentStep(step);
  };

  const booking = async () => {
    // booking with PROXY
    /*   const apiUrl = "http://localhost:3001/api/booking";
    const clientId = JSON.parse(sessionStorage.getItem("user")).id;
    const officeServiceId = sessionStorage.getItem("officeServiceId");
    const blockId = sessionStorage.getItem("selectedBlockId");

    const requestBody = {
      client_id: clientId,
      office_service_id: officeServiceId,
      block_id: blockId,
      note: details,
      // PROXY SPECIFIC
      method: "PUT",
      endpoint: "booking",
    }; */

    // booking without PROXY

    const apiUrl = `${BASE_URL}/booking`;
    const clientId = JSON.parse(sessionStorage.getItem("user")).id;
    const officeServiceId = sessionStorage.getItem("officeServiceId");
    const blockId = sessionStorage.getItem("selectedBlockId");

    const requestBody = {
      client_id: clientId,
      office_service_id: officeServiceId,
      block_id: blockId,
      note: details,
    };

    try {
      const headers = getHeaders();
      const response = await axios.put(apiUrl, requestBody, {
        headers: headers,
      });
      if (response.status === 200) {
        // pulisco sessionStorage
        cleanService();
        // pulisco state del wizard
        setSavedOffice("");
        setSavedService("");
        setSavedBlockId("");
        setSelectedSlots([]);
        setDetails("");

        setAppointmentsConfirmed(true);

        // salvo il booking_id in sessionStorage
        sessionStorage.setItem("bookingId", response.data.booking_id);
      }
    } catch (error) {
      console.log(error);

      if (error.response.status === 401) {
        setToken(null);
        window.location.href = "/login";
      }
    }
  };

  const handleButtonBookingClick = () => {
    booking();
  };

  return (
    <div className="container">
      <div className="steppers">
        <div className="steppers-header">
          <ul>
            <StepButton
              step={1}
              currentStep={currentStep}
              stepSet={stepSet}
              icons={icons}
            />
            <StepButton
              step={2}
              currentStep={currentStep}
              stepSet={stepSet}
              icons={icons}
            />
            <StepButton
              step={3}
              currentStep={currentStep}
              stepSet={stepSet}
              icons={icons}
            />
            <StepButton
              step={4}
              currentStep={currentStep}
              stepSet={stepSet}
              icons={icons}
            />
            <StepButton
              step={5}
              currentStep={currentStep}
              stepSet={stepSet}
              icons={icons}
            />
            <span className="steppers-index" aria-hidden="true">
              {`${currentStep}/5`}
            </span>
          </ul>
          {/*   <span className="steppers-index" aria-hidden="true">
            <span className={`${currentStep === 1 ? "active" : ""}`}>1</span>
            <span className={`${currentStep === 2 ? "active" : ""}`}>2</span>
            <span className={`${currentStep === 3 ? "active" : ""}`}>3</span>
            <span className={`${currentStep === 4 ? "active" : ""}`}>4</span>
          </span> */}
        </div>
      </div>

      {currentStep === 1 && (
        <div>
          {/*<OfficeServiceSelect onValid={() => setIsValidStep1(true)} />*/}
          {/*       <Step1 onValid={() => setIsValidStep1(true)} /> */}
          <Step1
            savedOffice={savedOffice}
            savedService={savedService}
            setSavedOffice={setSavedOffice}
            setSavedService={setSavedService}
          />
          <div className="flex justify-end mt-4">
            <button
              className="btn btn-primary"
              onClick={handleNext}
              disabled={!isValidStep1}
            >
              Avanti
            </button>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div>
          <Step2
            savedBlockId={savedBlockId}
            setSavedBlockId={setSavedBlockId}
            selectedSlots={selectedSlots}
            setSelectedSlots={setSelectedSlots}
          />
          <div className="flex justify-between mt-4">
            <button className="btn btn-primary mr-3 " onClick={handlePrev}>
              Indietro
            </button>
            <button
              className="btn btn-primary"
              disabled={!isValidStep2}
              onClick={handleNext}
            >
              Avanti
            </button>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div>
          <Step3 details={details} setDetails={setDetails} />
          <div className="flex justify-between mt-4">
            <button className="btn btn-primary mr-3" onClick={handlePrev}>
              Indietro
            </button>
            <button className="btn btn-primary" onClick={handleNext}>
              Avanti
            </button>
          </div>
        </div>
      )}
      {currentStep === 4 && (
        <div>
          <Step4 />
          <div className="flex justify-between mt-4">
            <button className="btn btn-primary mr-3" onClick={handlePrev}>
              Indietro
            </button>
            <button className="btn btn-primary" onClick={handleNext}>
              Avanti
            </button>
          </div>
        </div>
      )}
      {currentStep === 5 && (
        <div>
          <Step5
            appointmentsConfirmed={appointmentsConfirmed}
            setAppointmentsConfirmed={setAppointmentsConfirmed}
            stepSet={stepSet}
          />

          {!appointmentsConfirmed ? (
            <div className="flex justify-between mt-4">
              <button className="btn btn-primary mr-3" onClick={handlePrev}>
                Indietro
              </button>
              <button
                className="btn btn-primary"
                onClick={handleButtonBookingClick}
              >
                Conferma
              </button>
            </div>
          ) : (
            <div className="flex justify-between mt-4">
              <button
                className="btn btn-primary mr-3"
                onClick={() => stepSet(1)}
              >
                Nuovo appuntamento
              </button>
              <Link className="btn btn-primary" to="/">
                Torna alla home
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Wizard;

const StepButton = ({ step, currentStep, stepSet, icons }) => {
  /*   const isConfirmed =
    step <= currentStep ? (
      <>
        <svg className="icon icon-white steppers-success w-[1.778rem!important] h-[1.778rem!important]">
          <use href={`${icons}#it-check`} />
        </svg>
        <span className="visually-hidden">Confermato</span>
      </>
    ) : (
      <>
        <span className="visually-hidden">Step </span>
        {step}
      </>
    ); */

  const isActive = currentStep === step ? "active no-line" : "";

  return (
    <li
      className={`steppers-step ${isActive} ${
        step <= currentStep ? "confirmed" : ""
      } ${
        step === currentStep ? "lg:border-b-4 border-primary bg-red-300" : ""
      }}`}
    >
      <div
        className={"flex items-center justify-center"}
        /*   onClick={() => stepSet(step)} */
      >
        {/*    <span
          className="hidden lg:inline-block
        steppers-number"
        >
          {isConfirmed}
        </span> */}
        {step === 1
          ? "Luogo"
          : step === 2
          ? "Data e orario"
          : step === 3
          ? "Dettagli appuntamento"
          : step === 4
          ? "Richiedente"
          : "Riepilogo"}
      </div>

      {step < currentStep && (
        <>
          <svg
            className="icon steppers-success w-[1.778rem!important] h-[1.778rem!important]"
            aria-hidden="true"
          >
            <use href={`${icons}#it-check`}></use>
          </svg>
          <span className="visually-hidden">Confermato</span>
        </>
      )}
    </li>
  );
};
