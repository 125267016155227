import React from "react";

import "./Popup.css";

export const Popup = (props) => {
  return (
    <div className="container-popup" {...props}>
      <div className="styledCard" {...props}>
        {props.handleClose && (
          <div className="styledCloseIcon" onClick={() => props.handleClose()}>
            {" "}
            X
          </div>
        )}
        <div
          style={{
            cursor: "initial",
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};
