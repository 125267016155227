import AuthUser from "./AuthUser";
import { useEffect, useState } from "react";
import Appuntamenti from "./AppuntamentiUtente";
import { BASE_URL } from "../../server";
import getHeaders from "../../utils/handleHeaderFunc";

const User = () => {
  const { user } = AuthUser();

  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    const headers = getHeaders();
    // API call to fetch offices
    fetch(`${BASE_URL}/user_bookings/${user.id}`, {
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setUserDetail(data);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  /*   console.log("userDetail", userDetail); */

  return (
    <div className={"container"}>
      {/*      <h3 className={"capitalize mt-4"}>
        {user.firstname} {user.lastname}
      </h3> */}

      <Appuntamenti
        newBookings={userDetail.bookings}
        oldBookings={userDetail.old_booking}
      />
    </div>
  );
};

export default User;
