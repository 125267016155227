import React, { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Homepage } from "../../frontend/pages/Homepage";
import LoginForm from "./LoginForm";
import LoginPage from "./LoginPage";
/* import Dashboard from "./Dashboard";
import Servizio from "../../frontend/pages/Servizio"; */
import { HeaderSticky } from "../../frontend/components/header/Header";
import LoginSso from "./LoginSso";
import Footer from "../../frontend/components/footer/Footer";

const Guest = () => {
  const [isMobile, setIsMobile] = useState(
    sessionStorage.getItem("mobile") || false
  );

  useEffect(() => {
    // Ottieni l'URL corrente
    const url = new URL(window.location.href);

    // Estrai i parametri di query
    const queryParams = new URLSearchParams(url.search);

    // Leggi i parametri di query specifici
    const mobile = queryParams.get("mobile");

    if (mobile === "true") {
      setIsMobile(true);
      sessionStorage.setItem("mobile", true);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {!isMobile && <HeaderSticky />}
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/servizio" element={<Navigate to="/login" replace />} />
          <Route path="/login-page" element={<LoginPage />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/sso" element={<LoginSso />} />
          <Route
            path="/dashboard"
            element={
              <div className="container flex justify-center items-center flex-wrap h-full pt-10">
                <div className="text-center">
                  {/*<h1>404</h1>*/}
                  <h2>Accesso negato</h2>
                  <p>Non sei autorizzato ad accedere a questa pagina</p>
                  <p>
                    <Link to="/login" className="btn btn-primary btn-active">
                      Torna alla login
                    </Link>
                  </p>
                </div>
              </div>
            }
          />
        </Routes>
      </div>
      {!isMobile && <Footer />}
    </div>
  );
};

export default Guest;
