import React, { useEffect, useState } from "react";
import AuthUser from "../../../backend/pages/AuthUser";

export const Step4 = () => {
  const { user } = AuthUser();

  // {"id":"1","firstname":"matteo","lastname":"maggioni","competence_id":"4","fiscalcode":"MGGMTT94R31D4","email":"matteomaggioni94@gmail.com","created":"2023-01-19 16:10:16","updated":"2023-06-23 17:01:29"}
  return (
    <div className={"row mt-5"}>
      <div className="it-page-sections-container">
        <section className="it-page-section" id="applicant">
          <div className="cmp-card">
            <div className=" bg-gray-100 shadow-sm p-10">
              <div className="card-header border-0 p-0 mb-lg-30">
                <div className="d-flex">
                  <h2 className="title-xxlarge mb-1">Richiedente</h2>
                </div>
                <p className="subtitle-small mb-4 font-semibold">
                  Informazioni su di te
                </p>
              </div>
              <div className="card-body p-0">
                <div className="cmp-info-button-card">
                  <div className="card p-3 p-lg-4">
                    <div className="card-body p-0">
                      <h3 className="big-title mb-0">
                        {user.firstname} {user.lastname}
                      </h3>

                      <p className="card-info">
                        Codice Fiscale <span>{user.fiscalcode}</span>
                      </p>

                      <div className="accordion-item">
                        <div
                          className="accordion-header"
                          id="heading-collapse-parents"
                        >
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse-parents"
                            aria-expanded="true"
                            aria-controls="collapse-parents"
                            data-focus-mouse="false"
                          >
                            <span className="d-flex align-items-center">
                              Mostra tutto
                              <svg className="icon icon-primary icon-sm">
                                <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand"></use>
                              </svg>
                            </span>
                          </button>
                        </div>
                        <div
                          id="collapse-parents"
                          className="accordion-collapse collapse"
                          aria-labelledby="heading-collapse-parents"
                          data-bs-parent="#accordion-parents"
                          role="region"
                        >
                          <div className="accordion-body p-0">
                            <div className=" bg-white has-border border border-[#E6E6E6] p-3 p-lg-4">
                              <div className="card">
                                <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                                  <h4 className="title-large-semi-bold mb-3">
                                    Contatti
                                  </h4>
                                  {/*   <a
                                    href="#"
                                    className="d-none text-decoration-none"
                                  >
                                    <span className="text-button-sm-semi t-primary">
                                      Modifica
                                    </span>
                                  </a> */}
                                </div>

                                <div className="card-body p-0 mt-3">
                                  {!!user.phone && (
                                    <div className="single-line-info border-light">
                                      <div className="text-paragraph-small">
                                        Telefono
                                      </div>
                                      <div className="border-light">
                                        <p className="data-text font-semibold">
                                          {user.phone}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {!!user.email && (
                                    <div className="single-line-info border-light">
                                      <div className="text-paragraph-small ">
                                        Email
                                      </div>
                                      <div className="border-light">
                                        <p className="data-text font-semibold">
                                          {user.email}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="card-footer p-0 d-none"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

{
  /*     <div className="it-page-sections-container">
        <section className="it-page-section" id="applicant">
          <div className="cmp-card">
            <div className="card has-bkg-grey shadow-sm p-big">
              <div className="card-header border-0 p-0 mb-lg-30">
                <div className="d-flex">
                  <h2 className="title-xxlarge mb-1">Richiedente</h2>
                </div>
                <p className="subtitle-small mb-0">Informazioni su di te</p>
              </div>
              <div className="card-body p-0">
                <div className="cmp-info-button-card">
                  <div className="card p-3 p-lg-4">
                    <div className="card-body p-0">
                      <h3 className="big-title mb-0">
                        <span className="text-capitalize">
                          {user.firstname} {user.lastname}
                        </span>
                      </h3>

                      <p className="card-info">
                        Codice Fiscale <br />
                        <span>{user.fiscalcode}</span>
                      </p>
                      <p className="card-info">
                        Email <br />
                        <span>{user.email}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> */
}
