const getHeaders = () => {
  const bearerToken = sessionStorage.getItem("user_jwt");
  const basicToken = sessionStorage.getItem("basic_token");
  if (bearerToken) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    };
    return headers;
  }
  if (basicToken) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Basic ${basicToken}`,
    };
    return headers;
  }
};

export default getHeaders;
