import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Wizard from "../components/UI/Wizard";
import arrowLeft from "../../assets/icons/arrowLeft.svg";

function Servizio() {
  const navigate = useNavigate();

  return (
    <main className="my-4">
      <div className="container">
        <div className="col-12 col-lg-10">
          <div className="mb-3" onClick={() => navigate(-1)}>
            <a href="#" className="flex items-center">
              <img
                src={`${arrowLeft}`}
                className={"icon"}
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "4px",
                }}
                alt={"Torna alla pagina precedente"}
              />
              Torna alla home
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="cmp-hero">
              <section className="bg-white align-items-start">
                <div className="it-hero-text-wrapper pt-0 ps-0 pb-3 pb-lg-4">
                  <h1
                    className="text-black hero-title"
                    data-element="page-name"
                  >
                    Prenotazione appuntamento
                  </h1>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Wizard />
      </div>
    </main>
  );
}

export default Servizio;
